/* eslint-disable import/no-unused-modules */
import React from 'react';
import { graphql } from 'gatsby';
import Helmet from '../../components/Helmet';
import Features from '../../modules/features';
import withI18n from '../../hocs/withI18n';

export const Head = withI18n(() => {
  return <Helmet pageName="featuresInvoice" />;
});

const InvoiceFeature = () => {
  return <Features featureName="invoice-management-software-for-business" />;
};
export default InvoiceFeature;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
